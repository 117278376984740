import * as React from "react";
import { Box, Paper, Typography } from "@mui/material";

export default function About() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        marginTop: "6rem",
        marginBottom: "1rem",
      }}
    >
      <Box sx={{ width: { xs: "92.5%", md: "40%" } }}>
        <Paper
          sx={{
            padding: "1rem",
            minHeight: { xs: "calc(100vh - 276px)", md: "calc(100vh - 360px)" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h3">About</Typography>
            <Typography variant="h6" textAlign="center" style={{
              marginTop: "2rem",
              whiteSpace: "pre-line",
              tabSize: 4,
              MozTabSize: 4,
            }}>
              {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'}
              As a seasoned software developer with six years of experience, I've always had a passion for creating. My journey into woodworking began as a natural extension of this desire to build. Instead of something digital, I wanted something physical. Starting with small projects and gradually honing my skills, I found myself drawn to the timeless artistry of woodworking.
              {'\n'}
              {'\n'}  
              {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'}
              What started as a hobby soon became a profound pursuit as I delved deeper into the world of traditional craftsmanship. Embracing the challenge of mastering hand tools, I embarked on larger projects, pushing the boundaries of my abilities with each endeavor.
              {'\n'}
              {'\n'}
              {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'}
              In my journey, I've balanced living with the modern technology of software development and the timeless trades of woodworking. Each piece I create reflects an engineers attentional to detail and handcrafted artistry.
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
