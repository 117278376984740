import { Button, Box, Container, Typography, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Masonry from "@mui/lab/Masonry";
import { ImageRepo } from "../../Data/ImageRepo";
import { Images } from "../../Data/Images";
import * as React from "react";
import { useState } from "react";

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.0,
  transition: theme.transitions.create("opacity"),
}));

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

export default function Projects() {
  const [isLoaded, setIsLoaded] = useState(false);

  var localImages = Images;

  function handleOnLoad(index) {
    if (Images.length === index + 1) {
      setIsLoaded(true);
    }
  }

  return (
    <Box sx={{ mt: 2, marginTop: "5rem" }}>
      <Container
        maxWidth="xl"
        sx={{
          px: "4px",
          minHeight: "calc(100vh)",
        }}
      >
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          sx={{ display: isLoaded ? "inherit" : "none", height: "100%" }}
        >
          {localImages.map((item, index) => {
            return (
              <Button
                key={item.title}
                focusRipple
                href={`/projects/${item.project}`}
                sx={{
                  width: "100%",
                  "&:hover": {
                    "& .MuiTypography-root": {
                      opacity: 1,
                    },
                    "& .MuiImageBackdrop-root": {
                      opacity: 0.4,
                    },
                  },
                }}
              >
                <ImageBackdrop className="MuiImageBackdrop-root" />
                <Image>
                  <Typography
                    component="span"
                    variant="h5"
                    sx={{
                      textAlign: "center",
                      position: "absolute",
                      opacity: "0",
                      maxWidth: "90%",
                    }}
                  >
                    {item.title}
                  </Typography>
                </Image>
                <img
                  src={`${ImageRepo}${item.project}/${item.image}`}
                  srcSet={`${ImageRepo}${item.project}/${item.image}`}
                  alt={item.title}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  onLoad={() => {
                    handleOnLoad(index);
                  }}
                  loading="eager"
                ></img>
              </Button>
            );
          })}
        </Masonry>

        {!isLoaded && (
          <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
            {localImages.map((item, index) => {
              return (
                <div key={index}>
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      height: {
                        xs: "170px",
                        sm: "300px",
                        md: "350px",
                        lg: "450px",
                        xl: "500px",
                      },
                    }}
                  />
                </div>
              );
            })}
          </Masonry>
        )}
      </Container>
    </Box>
  );
}
