import {
  Box,
  Toolbar,
  Typography,
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem
} from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router-dom";
import * as _ from "lodash";
import MenuIcon from "@mui/icons-material/Menu";

export default function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState();

  var location = useLocation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          style={{ background: "white", color: "black" }}
        >
          <Toolbar>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleOpenNavMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button href="/projects">
                    <Typography textAlign="center">Projects</Typography>
                  </Button>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button href="/about">
                    <Typography textAlign="center">About</Typography>
                  </Button>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button href="/contact">
                    <Typography textAlign="center">Contact</Typography>
                  </Button>
                </MenuItem>
              </Menu>
            </Box>
            <Button
              color="inherit"
              href="/"
              sx={{
                flexGrow: { sm: 1, md: 0 },
                display: { xs: "flex", md: "block" },
              }}
            >
                <img
                    src={`${process.env.PUBLIC_URL}/Logo.png`}
                    alt="Logo"
                    style={{ height: "68px" }}
                />
            </Button>
            <Button
              color="inherit"
              href="/projects"
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  color: _.includes(location.pathname, "/projects") ? "black" : location.pathname === "/" ? "black" : "grey",
                  fontWeight: _.includes(location.pathname, "/projects")
                  ? "bold"
                  : location.pathname === "/"
                  ? "bold"
                  : "regular",
                }}
              >
                Projects
              </Typography>
            </Button>
            {/* <Button color="inherit" href="/shop">
              Shop
            </Button> */}
            <Button
              color="inherit"
              href="/about"
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  color: _.includes(location.pathname, "/about") ? "black" : "grey",
                  fontWeight: _.includes(location.pathname, "/about")
                  ? "bold"
                  : "regular",
                }}
              >
                About Me
              </Typography>
            </Button>
            <Button
              color="inherit"
              href="/contact"
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  color: _.includes(location.pathname, "/contact") ? "black" : "grey",
                  fontWeight: _.includes(location.pathname, "/contact")
                  ? "bold"
                  : "regular",
                }}
              >
                Contact Me
              </Typography>
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
