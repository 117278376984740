import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import * as React from "react";
import {
  Button,
  Box,
  InputLabel,
  TextField,
  Paper,
  Typography,
} from "@mui/material";

const initialFormValues = {
  user_name: "",
  user_email: "",
  message: "",
  formSubmitted: false,
  success: false,
};

export default function Contact() {
  const form = useRef();
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [submissionMessage, setSubmissionMessage] = useState("");

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("user_name" in fieldValues)
      temp.user_name = fieldValues.user_name ? "" : "This field is required.";

    if ("user_email" in fieldValues) {
      temp.user_email = fieldValues.user_email ? "" : "This field is required.";
      if (fieldValues.user_email)
        temp.user_email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(
          fieldValues.user_email
        )
          ? ""
          : "Email is not valid.";
    }

    if ("message" in fieldValues)
      temp.message = fieldValues.message ? "" : "This field is required.";

    setErrors({
      ...temp,
    });
  };
  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (formIsValid(values)) {
      console.log("Trying to send");
      emailjs
        .sendForm(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          form.current,
          {
            publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
          }
        )
        .then(
          () => {
            setSubmissionMessage("Thank you for reaching out!");
            setValues({ ...values, success: true });
          },
          (error) => {
            setSubmissionMessage(
              "There was an error sending the contact request. Please refresh the page and try again."
            );
          }
        );
    }
  };
  const formIsValid = (fieldValues) => {
    const isValid =
      fieldValues.user_name &&
      fieldValues.user_email &&
      fieldValues.message &&
      Object.values(errors).every((x) => x === "");

    return isValid;
  };

  return values.success ? (
    <Box
      sx={{
        mt: 2,
        margin: "auto",
        marginTop: "5rem",
        marginBottom: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          padding: "1rem",
          minHeight: { xs: "calc(100vh - 160px)", md: "calc(100vh - 263px)" },
          width: "50%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h3">Contact Me</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: "1 1 auto",
          }}
        >
          <Typography variant="h6">{submissionMessage}</Typography>
        </Box>
      </Paper>
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        marginTop: "5rem",
        marginBottom: "1rem",
      }}
    >
      <Box sx={{ width: { xs: "92.5%", md: "40%" } }}>
        <form
          ref={form}
          onSubmit={handleFormSubmit}
          style={{
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <Paper
            sx={{
              padding: "1rem",
              minHeight: {
                xs: "calc(100vh - 272px)",
                md: "calc(100vh - 375px)",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography variant="h3">Contact Me</Typography>
            </Box>
            <InputLabel htmlFor="user_name">Name</InputLabel>
            <TextField
              name="user_name"
              aria-describedby="user-name-text"
              sx={{ width: "50%" }}
              required
              variant="outlined"
              autoComplete="none"
              onBlur={handleInputValue}
              onChange={handleInputValue}
              {...(errors["user_name"] && {
                error: true,
                helperText: errors["user_name"],
              })}
            />

            <InputLabel htmlFor="user_email">Email</InputLabel>
            <TextField
              name="user_email"
              aria-describedby="user-email-text"
              sx={{ width: "50%" }}
              required
              variant="outlined"
              autoComplete="none"
              onBlur={handleInputValue}
              onChange={handleInputValue}
              {...(errors["user_email"] && {
                error: true,
                helperText: errors["user_email"],
              })}
            />

            <InputLabel htmlFor="message">Message</InputLabel>
            <TextField
              name="message"
              aria-describedby="message-text"
              multiline
              rows={4}
              variant="outlined"
              required
              autoComplete="none"
              onBlur={handleInputValue}
              onChange={handleInputValue}
              {...(errors["message"] && {
                error: true,
                helperText: errors["message"],
              })}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button type="submit" variant="contained" size="large">
                Submit
              </Button>
            </Box>
          </Paper>
        </form>
      </Box>
    </Box>
  );
}
