export const Images = [
  {
    image: "1.jpg",
    title: "Green Dresser",
    project: "dresser",
  }
,
  {
    image: "1.jpg",
    title: "Ebonized Walnut Flat Top Inlayed Box",
    project: "ebonizedwalnutflattopbox",
  }
,
  {
    image: "1.jpg",
    title: "Ebonized Walnut Raised Top Inlayed Box",
    project: "ebonizedwalnutraisedtopbox",
  }
,
  {
    image: "1.jpg",
    title: "Stylized Ebonized White Oak Picture Frame",
    project: "ebonizedwhiteoakpictureframe1",
  }
,
  {
    image: "1.jpg",
    title: "Standard Ebonized White Oak Picture Frame",
    project: "ebonizedwhiteoakpictureframe2",
  }
,
  {
    image: "1.jpg",
    title: "Green Endtable",
    project: "endtable",
  }
,
  {
    image: "1.jpg",
    title: "Oak Boxes",
    project: "oakboxes",
  }
,
  {
    image: "1.jpg",
    title: "Red Oak Picture Frame With Cat Drawing",
    project: "oakpictureframe",
  }
,
  {
    image: "1.jpg",
    title: "Stained Glass Windows For Custom Door",
    project: "stainedglassdoor",
  }
,
  {
    image: "1.jpg",
    title: "Walnut Boxes",
    project: "walnutboxes",
  }
]
