export const Projects = {
  dresser: {
    projectName: "dresser",
    projectTitle: "Green Dresser",
    projectDescription: "Handcrafted dresser using only traditional hand tools, featuring elegant walnut accents that pair beautifully with a fresh spring green finish. The natural walnut top and drawer pulls add a touch of sophistication, blending classic craftsmanship with modern style. ",
    projectImages: ["1.jpg","2.jpg","3.jpg"],
    projectThumbnails: ["thumbnails/1.jpg","thumbnails/2.jpg","thumbnails/3.jpg"],
  },
  ebonizedwalnutflattopbox: {
    projectName: "ebonizedwalnutflattopbox",
    projectTitle: "Ebonized Walnut Flat Top Inlayed Box",
    projectDescription: "Ebonized walnut box featuring a sapele sunburst pattern on the box lid with an art deco inlay of lacewood wrapped with maple. ",
    projectImages: ["1.jpg","2.jpg","3.jpg","4.jpg","5.jpg","6.jpg"],
    projectThumbnails: ["thumbnails/1.jpg","thumbnails/2.jpg","thumbnails/3.jpg","thumbnails/4.jpg","thumbnails/5.jpg","thumbnails/6.jpg"],
  },
  ebonizedwalnutraisedtopbox: {
    projectName: "ebonizedwalnutraisedtopbox",
    projectTitle: "Ebonized Walnut Raised Top Inlayed Box",
    projectDescription: "Ebonized walnut box featuring a sapele sunburst pattern on the box lid with an inlay of lacewood wrapped with maple. ",
    projectImages: ["1.jpg","2.jpg","3.jpg","4.jpg","5.jpg"],
    projectThumbnails: ["thumbnails/1.jpg","thumbnails/2.jpg","thumbnails/3.jpg","thumbnails/4.jpg","thumbnails/5.jpg"],
  },
  ebonizedwhiteoakpictureframe1: {
    projectName: "ebonizedwhiteoakpictureframe1",
    projectTitle: "Stylized Ebonized White Oak Picture Frame",
    projectDescription: "Ebonized white oak picture frame, crafted with a distinctive lap joint in the corners to add vertical height.",
    projectImages: ["1.jpg","2.jpg","3.jpg"],
    projectThumbnails: ["thumbnails/1.jpg","thumbnails/2.jpg","thumbnails/3.jpg"],
  },
  ebonizedwhiteoakpictureframe2: {
    projectName: "ebonizedwhiteoakpictureframe2",
    projectTitle: "Standard Ebonized White Oak Picture Frame",
    projectDescription: "Ebonzied white oak picture frame. Standard picture frame style with splines in the corners for reinforcement.",
    projectImages: ["1.jpg","2.jpg","3.jpg"],
    projectThumbnails: ["thumbnails/1.jpg","thumbnails/2.jpg","thumbnails/3.jpg"],
  },
  endtable: {
    projectName: "endtable",
    projectTitle: "Green Endtable",
    projectDescription: "Handcrafted end table created using only traditional hand tools, designed to match the dresser with elegant walnut accents and a fresh spring green finish. The drawer features a handcut dovetail drawer box that utilizes wooden slides to guide it. The natural walnut top and drawer pulls add a touch of sophistication, combining classic craftsmanship with modern style.",
    projectImages: ["1.jpg","2.jpg","3.jpg"],
    projectThumbnails: ["thumbnails/1.jpg","thumbnails/2.jpg","thumbnails/3.jpg"],
  },
  oakboxes: {
    projectName: "oakboxes",
    projectTitle: "Oak Boxes",
    projectDescription: "Red oak boxes of various sizes, inspired by Japanese minimalist design. Featuring walnut splines that not only strengthen the corners but also provide a striking contrast to the oak, these boxes blend functionality with elegant simplicity.",
    projectImages: ["1.jpg","2.jpg","3.jpg","4.jpg","5.jpg","6.jpg","7.jpg"],
    projectThumbnails: ["thumbnails/1.jpg","thumbnails/2.jpg","thumbnails/3.jpg","thumbnails/4.jpg","thumbnails/5.jpg","thumbnails/6.jpg","thumbnails/7.jpg"],
  },
  oakpictureframe: {
    projectName: "oakpictureframe",
    projectTitle: "Red Oak Picture Frame With Cat Drawing",
    projectDescription: "Red oak picture frame featuring a custom graphite drawing of a sleeping cat. The drawing was also done by me for a client that wanted a framed drawing of their tortoise shell cat.",
    projectImages: ["1.jpg","2.jpg","3.jpg"],
    projectThumbnails: ["thumbnails/1.jpg","thumbnails/2.jpg","thumbnails/3.jpg"],
  },
  stainedglassdoor: {
    projectName: "stainedglassdoor",
    projectTitle: "Stained Glass Windows For Custom Door",
    projectDescription: "This stained glass window features an intricate design centered around three circles of varying sizes, each crafted from unique glass elements. The circles incorporate an old light cover, a handmade rondel, and a glass lid, adding character to the piece. The construction of the window employs both leading and foiling techniques. The larger circles are too thick to be secured with lead. Instead they were fixed with copper foil that could then be soldered. The surrounding frame is assembled with lead came of varying thicknesses and designs, providing a robust structure to the rest of the piece.",
    projectImages: ["1.jpg","2.jpg","3.jpg","4.jpg"],
    projectThumbnails: ["thumbnails/1.jpg","thumbnails/2.jpg","thumbnails/3.jpg","thumbnails/4.jpg"],
  },
  walnutboxes: {
    projectName: "walnutboxes",
    projectTitle: "Walnut Boxes",
    projectDescription: "Walnut boxes of various sizes, inspired by Japanese minimalist design. Featuring walnut splines that subtly enhance the sleek and minimal look, these boxes combine elegant simplicity with refined craftsmanship",
    projectImages: ["1.jpg","2.jpg","3.jpg","4.jpg","5.jpg","6.jpg","7.jpg"],
    projectThumbnails: ["thumbnails/1.jpg","thumbnails/2.jpg","thumbnails/3.jpg","thumbnails/4.jpg","thumbnails/5.jpg","thumbnails/6.jpg","thumbnails/7.jpg"],
  },
};
