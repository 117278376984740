import {
  Box,
  Typography,
  Container,
  Divider,
  Link,
  Stack,
  // IconButton,
  Button,
} from "@mui/material";
import * as React from "react";
import Copyright from "../Copyright/Copyright";
import EmailIcon from "@mui/icons-material/Email";
// import Instagram from "@mui/icons-material/Instagram";
// import XIcon from "@mui/icons-material/X";

export default function Footer() {
  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
          paddingTop: 2,
          paddingBottom: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        component="footer"
      >
        <Divider style={{ width: "calc(100vw - 5vw)" }} />
        <Container
          maxWidth="xl"
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "space-between",
          }}
        >
          <Stack sx={{ p: 2 }}>
            <Typography variant="h5">Quick Links</Typography>
            <Stack sx={{ pl: 1 }}>
              <Link
                href="/projects"
                variant="fullWidth"
                underline="hover"
                color="inherit"
              >
                Projects
              </Link>
              <Link
                href="/about"
                variant="fullWidth"
                underline="hover"
                color="inherit"
              >
                About Me
              </Link>
              <Link
                href="/contact"
                variant="fullWidth"
                underline="hover"
                color="inherit"
              >
                Contact Me
              </Link>
            </Stack>
          </Stack>
          <Stack sx={{ p: 2 }}>
            {/* <Box sx={{ flexDirection: "row", display: "flex" }}>
              <Button sx={{ padding: 0 }} style={{ textTransform: "none" }}>
                <Instagram sx={{ paddingLeft: "2px", paddingRight: "8px" }} />
                <Typography variant="subtitle1">Instagram Link</Typography>
              </Button>
            </Box>
            <Box sx={{ flexDirection: "row", display: "flex" }}>
              <Button sx={{ padding: 0 }} style={{ textTransform: "none" }}>
                <XIcon sx={{ paddingLeft: "2px", paddingRight: "6px" }} />
                <Typography variant="subtitle1">Twitter Link</Typography>
              </Button>
            </Box>
            <Box sx={{ flexDirection: "row", display: "flex" }}>
              <Button sx={{ padding: 0 }} style={{ textTransform: "none" }}>
                <Box height={30} width={30} sx={{ paddingRight: "4px" }}>
                  <img
                    src="/EtsyIcon.png"
                    alt="etsy-icon"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      color: "black",
                    }}
                  />
                </Box>
                <Typography variant="subtitle1">Etsy Link</Typography>
              </Button>
            </Box> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Button sx={{ padding: 0 }} style={{ textTransform: "none" }}>
                <EmailIcon sx={{ paddingRight: "8px", paddingLeft: "2px" }} />
                <Typography variant="subtitle1">
                  nickhayes@nickswoodworks.com
                </Typography>
              </Button>
            </Box>
          </Stack>
        </Container>
        <Container
          maxWidth="xl"
          sx={{
            display: { xs: "flex", md: "none" },
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
            {/* <IconButton color="inherit" aria-label="instagram" sx={{ mr: 2 }}>
              <Instagram />
            </IconButton>
            <IconButton color="inherit" aria-label="x" sx={{ mr: 2 }}>
              <XIcon />
            </IconButton>
            <Button
              color="inherit"
              aria-label="etsy"
              height={40}
              width={40}
              sx={{
                padding: 0,
                "&:last-child": {
                  margin: 0,
                },
              }}
            >
              <Box height={30} width={30}>
                <img
                  src="/EtsyIcon.png"
                  alt="etsy-icon"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    color: "black",
                  }}
                />
              </Box>
            </Button> */}
          </Stack>
          <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
            <Link
              href="/projects"
              variant="fullWidth"
              underline="hover"
              color="inherit"
            >
              Projects
            </Link>
            <Typography>/</Typography>
            <Link
              href="/about"
              variant="fullWidth"
              underline="hover"
              color="inherit"
            >
              About Me
            </Link>
            <Typography>/</Typography>
            <Link
              href="/contact"
              variant="fullWidth"
              underline="hover"
              color="inherit"
            >
              Contact Me
            </Link>
          </Stack>
        </Container>
        <Divider style={{ width: "calc(100vw - 5vw)" }} sx={{ marginY: 2 }} />
        <Copyright />
      </Box>
    </>
  );
}
