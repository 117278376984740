import * as React from "react";
import { Box, Paper, Typography } from "@mui/material";

export default function Error() {
  return (
    <Box
      sx={{
        mt: 2,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Paper
        sx={{
          padding: "1rem",
          minHeight: "calc(100vh - 372px)",
          width: "50%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: "1 1 auto",
          }}
        >
          <Typography variant="h3" color="error">
            404
          </Typography>
          <Typography variant="h6">Page Not Found!</Typography>
        </Box>
      </Paper>
    </Box>
  );
}
